import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';

import HomeScreen from 'containers/home';
import LoginScreen from 'containers/auth/Login';
import Playground from 'containers/playground';

import config from 'config';

const NavRoutes: FunctionComponent = () => {
    const testModeEnabled = config.env === 'dev';
    return (
        <Router history={history}>
            <Routes>
                <Route path='/' element={<HomeScreen test={testModeEnabled} />} />
                <Route path='/login' element={<LoginScreen />} />
                {testModeEnabled && (
                    <Route path='/playground' element={<Playground />} />
                )}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
