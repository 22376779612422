import React, { useEffect } from 'react';
import { MotionValue, motion, useScroll, useTransform } from 'framer-motion';

import Card from 'components/Card';
import Span from 'components/Span';
import Text from 'components/Text';
import Flex from 'components/Flex';

import { ALIGN_ITEMS, COLOR, FONT_SIZE, FONT_WEIGHT, SPACING } from 'entities/tailwind';

import files from 'assets/files';

import Motion from 'lib/Motion';

interface InformationProps {
    refScrollY: MotionValue<number>
}

const Information = ({ refScrollY }: InformationProps):JSX.Element => {
    const firstCardRef = React.useRef<HTMLDivElement>(null);

    const scale = useTransform(refScrollY, [0, 0.08], [1, 0.95]);
    const bottom = useTransform(refScrollY, [0, 0.08], ['-250px', '50px']);

    return (
        <Flex
            fill
            direction='column'
            xPadding={SPACING.none}
            salt='relative overflow-hidden'
        >
            <motion.div
                ref={firstCardRef}
                style={{ scale }}
                className='pb-20'
            >

                <Card
                    background='bg-[url("assets/files/cardOneBackground.png")] bg-contain bg-no-repeat'
                    style={{
                        backgroundPositionX: 'right',
                        backgroundPositionY: 'top',
                    }}
                    header={(
                        <motion.div variants={Motion.slideIn('left', 'spring', 0.5, 1)}>
                            <Flex yPadding={SPACING.small}>
                                <Text size={FONT_SIZE.cardHeader}><Span weight={FONT_WEIGHT.BOLD} color={COLOR.primaryLight}>59%</Span></Text>
                            </Flex>
                        </motion.div>
                    )}
                    desc={(
                        <motion.div variants={Motion.slideIn('left', 'spring', 0.5, 1)}>
                            <Flex>
                                <Text size={FONT_SIZE.smaller}>
                                    <Span>
                                        These emergency injury deaths could have been prevented with timely&nbsp;
                                        <Span color={COLOR.primaryLight} weight={FONT_WEIGHT.BOLD}>first aid</Span>
                                    &nbsp;intervention.
                                    </Span>
                                </Text>
                            </Flex>
                        </motion.div>
                    )}
                    footer={(
                        <div className='max-w-[350px]'>
                            <Text size={FONT_SIZE.cardFooter}>
                                <Span italic color={COLOR.white}>
                                    *Source: “Mapping the public first-aid training landscape:
                                    <br />
                                    a scoping review”, Heard et al., Disasters, Vol. 44, Issue 1, 2020.
                                </Span>
                            </Text>
                        </div>
                    )}
                />
            </motion.div>

            <motion.div style={{ bottom }} className='absolute w-full'>

                <Card
                    style={{
                        backgroundPositionY: 'calc(50% + 1.6rem)',
                    }}
                    header={(
                        <motion.div variants={Motion.slideIn('left', 'spring', 0.5, 1)}>
                            <Flex yPadding={SPACING.large} direction='column' align={ALIGN_ITEMS.start} gap={SPACING.extraSmall}>
                                <img src={files.images.people} alt='card-two-people' className=' object-cover max-w-[231px]' />
                                <Text size='text-[2.5rem]'>
                                    <Span weight={FONT_WEIGHT.BOLD} color={COLOR.primaryLight}>
                                        9 OUT OF 10
                                    </Span>
                                </Text>
                            </Flex>
                        </motion.div>
                    )}
                    background='bg-[url("assets/files/cardTwoBackground.png")] bg-contain bg-right bg-no-repeat'
                    desc={(
                        <motion.div variants={Motion.slideIn('left', 'spring', 0.5, 1)}>
                            <Flex>
                                <Text size={FONT_SIZE.smaller}>
                                    <Span>
                                        A staggering&nbsp;
                                        <Span color={COLOR.primaryLight} weight={FONT_WEIGHT.BOLD}>90% of Malaysians</Span>
                                    &nbsp;did not save their local emergency hotline.
                                    </Span>
                                </Text>
                            </Flex>
                        </motion.div>
                    )}
                    footer={(
                        <div className='max-w-[350px]'>
                            <Text size={FONT_SIZE.cardFooter}>
                                <Span italic color={COLOR.white}>
                                    *Source: Street Interview, Klang Valley July 2023
                                </Span>
                            </Text>
                        </div>
                    )}
                />
            </motion.div>

        </Flex>
    );
};

export default Information;
