import auth from 'redux/slices/auth/selectors';
import contact from 'redux/slices/contact/selectors';
import pdf from 'redux/slices/pdf/selectors';
import ui from 'redux/slices/ui/selectors';

import { ISMCVContactInfo } from 'entities/startup';

import { PlatformEnum } from 'entities/base';
import { RootState } from './store';

const authGetLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const authGetLoginError = (state: RootState): string => auth.getLoginError(state.auth);
const getAuthAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);
const authUserId = (state: RootState): string => auth.userId(state.auth);
const authUserPlatform = (state: RootState): PlatformEnum => auth.userPlatform(state.auth);

const uiGetIsOpenContactModal = (state: RootState): boolean => ui.getIsOpenContactModal(state.ui);
const uiGetContactVCard = (state: RootState): string => ui.getContactVCardUrl(state.ui);
const uiIsEbookModalOpen = (state: RootState): boolean => ui.isEbookModalOpen(state.ui);
const uiSMCVContactInfo = (state: RootState): ISMCVContactInfo => ui.getSMCVContactInfo(state.ui);
const uiIsFooterInView = (state: RootState): boolean => ui.isFooterInView(state.ui);

const contactSaveContactAttempting = (state: RootState): boolean => contact.saveContactAttempting(state.contact);
const contactSaveContactError = (state: RootState): string => contact.saveContactError(state.contact);

const contactGetSavedContactCounterAttempting = (state: RootState): boolean => contact.getSavedContactCounterAttempt(state.contact);
const contactGetSavedContactCounterError = (state: RootState): string => contact.getSavedContactCounterError(state.contact);
const contactGetSavedContactCounter = (state: RootState): number => contact.getSavedContactCounter(state.contact);

const pdfDownloadEbookAttempting = (state: RootState): boolean => pdf.downloadEbookAttempting(state.pdf);
const pdfDownloadEbookError = (state: RootState): string => pdf.downloadEbookError(state.pdf);

export default {
    authGetLoginAttempting,
    authGetLoginError,
    getAuthAuthToken,
    authUserId,
    authUserPlatform,

    uiGetIsOpenContactModal,
    uiGetContactVCard,
    uiIsEbookModalOpen,
    uiSMCVContactInfo,
    uiIsFooterInView,

    contactSaveContactAttempting,
    contactSaveContactError,
    contactGetSavedContactCounterAttempting,
    contactGetSavedContactCounterError,
    contactGetSavedContactCounter,

    pdfDownloadEbookAttempting,
    pdfDownloadEbookError,
};
