import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';
import { IGetContactCounterResponse, ISaveContactApiParams } from 'entities/contact';
import IContactGateway from './ContactBase';

export default class ContactGateway extends IContactGateway {
    async getSavedContactCounter(): GatewayResponse<IGetContactCounterResponse> {
        const response: ApiResponse<IGetContactCounterResponse> = await this.api.get('contact/count');
        return this.process(response);
    }

    async saveContact(params: ISaveContactApiParams): GatewayResponse<null> {
        const response : ApiResponse<null> = await this.api.post('contact', params);
        return this.process(response);
    }
}
