import React from 'react';
import { Variants, motion } from 'framer-motion';

import { SPACING, XPADDING_VARIANT, YPADDING_VARIANT } from 'entities/tailwind';

import Flex from './Flex';

interface FooterComponentProps {
    children: React.ReactNode;
    rounded?: boolean;
    variant?: Variants;
}

const Footer = ({
    children,
    rounded,
    variant,
}:FooterComponentProps):JSX.Element => {
    const roundedStyle = rounded ? 'rounded-b-lg' : 'rounded-none';

    const renderVariation = () => {
        if (variant) {
            return (
                <motion.div variants={variant}>
                    {children}
                </motion.div>
            );
        }
        return children;
    };

    return (
        <Flex noPadding stagger>
            <div className={`bg-smcv-primary-light w-full h-full text-smcv-secondary
            min-h-[42px] flex items-center 
            ${XPADDING_VARIANT[SPACING.default]} 
            ${YPADDING_VARIANT[SPACING.extraSmall]}
            ${roundedStyle}`}
            >
                {renderVariation()}
            </div>
        </Flex>
    );
};

Footer.defaultProps = {
    rounded: false,
    variant: undefined,
};

export default Footer;
