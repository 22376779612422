import { fork } from 'redux-saga/effects';

import PdfGateway from 'api/Pdf';

import { RootSagaReturnType } from 'sagas/types';

import watchDownloadEbook from './downloadEbook';

export default (api: PdfGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchDownloadEbook, api);
    }
    return {
        rootSaga,
    };
};
