import React from 'react';
import { FONT_SIZE, TEXT_ALIGN, TEXT_ALIGN_VARIANTS } from 'entities/tailwind';

interface TextComponentProps {
    size?: FONT_SIZE | string;
    align?: TEXT_ALIGN;
    children: React.ReactNode;
    fill?: boolean;
    salt?: React.ComponentProps<'div'>['className'];
}

const FONT_SIZE_VARIANT: Record<FONT_SIZE, string> = {
    [FONT_SIZE.cardFooter]: 'text-cardFooter md:text-smallest',
    [FONT_SIZE.cardHeader]: 'text-cardHeader',
    [FONT_SIZE.smallest]: 'text-smallest',
    [FONT_SIZE.smaller]: 'text-smaller',
    [FONT_SIZE.small]: 'text-small',
    [FONT_SIZE.DEFAULT]: 'text-normal',
    [FONT_SIZE.large]: 'text-large md:text-[2.5rem]',
    [FONT_SIZE.larger]: 'sm:text-[2rem] text-larger md:text-largest',
    [FONT_SIZE.largest]: 'text-largest',
};

const Text = ({
    size = FONT_SIZE.DEFAULT,
    align = TEXT_ALIGN.start,
    fill = false,
    salt,
    children,
}:TextComponentProps):JSX.Element => {
    const containerWidth = fill ? 'w-full' : '';

    let fontSize:string;
    if (typeof size === 'string') {
        fontSize = size;
    } else {
        fontSize = size !== undefined ? FONT_SIZE_VARIANT[size] : FONT_SIZE_VARIANT[FONT_SIZE.DEFAULT];
    }

    const saltClassname = salt !== undefined ? salt : '';

    return (
        <div className={`${containerWidth} ${fontSize.trim()} ${TEXT_ALIGN_VARIANTS[align]} text-black ${saltClassname.trim()}`}>{children}</div>
    );
};

Text.defaultProps = {
    size: FONT_SIZE.DEFAULT,
    align: TEXT_ALIGN.start,
    fill: false,
    salt: undefined,
};

export default Text;
