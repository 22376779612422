import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';
import ContactGateway from 'api/Contact';
import Actions from 'redux/Actions';

export default function* watchGetCounter(api: ContactGateway):SagaWatcherReturnType {
    yield takeEvery('contact/contactGetSavedContactCounterAttempt', handleGetCounter, api);
}

function* handleGetCounter(api: ContactGateway) {
    const res = yield* call([api, api.getSavedContactCounter]);

    if (res.status === GatewayResponseStatus.Error) {
        yield put(Actions.contactGetSavedContactCounterFailure(res.message || ''));
    }

    if (res.status === GatewayResponseStatus.Success) {
        yield put(Actions.contactGetSavedContactCounterSuccess(res.data.count));
    }
}
