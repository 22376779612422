import React from 'react';
import { motion, useInView } from 'framer-motion';

import Flex from 'components/Flex';
import Text from 'components/Text';
import Span from 'components/Span';
import Button from 'components/Button';

import Motion from 'lib/Motion';

import { ALIGN_ITEMS, COLOR, FONT_SIZE, FONT_WEIGHT, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';

import files from 'assets/files';

const VideoSection = ():JSX.Element => {
    const [isMuted, setIsMuted] = React.useState<boolean>(true);

    const videoRef = React.useRef<HTMLVideoElement>(null);
    const isInView = useInView(videoRef);

    const videoPlayHandler = () => {
        if (videoRef.current) {
            const video = videoRef.current;
            if (video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) {
                if (video.muted) {
                    video.muted = false;
                    setIsMuted(false);
                } else {
                    video.muted = true;
                    setIsMuted(true);
                }
            }
        }
    };

    React.useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            const handleContextMenu = (e: Event) => {
                e.preventDefault();
            };
            videoElement.addEventListener('contextmenu', handleContextMenu);

            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.keyCode === 123 || (event.ctrlKey && event.shiftKey && event.keyCode === 73)) {
                    event.preventDefault();
                }
            };

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                videoElement.removeEventListener('contextmenu', handleContextMenu);
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
        return undefined;
    }, []);

    React.useEffect(() => {
        if (videoRef.current) {
            if (isInView) {
                videoRef.current.play();
                setIsMuted(true);
            } else {
                videoRef.current.pause();
            }
        }
    }, [isInView]);

    return (
        <Flex xPadding={SPACING.none} fill direction='column' align={ALIGN_ITEMS.start} stagger>
            <img src={files.images.videoHeading} alt='Save A Number, Save A Life' className='max-h-[100px] max-w-[80%]' />
            <motion.div variants={Motion.textVariant(0.6)}>
                <Text size={FONT_SIZE.smaller} align={TEXT_ALIGN.start}>
                    <Span>
                        Malaysians have neglected to save their local emergency
                        hotline, a vital lifeline in times of need. We urge you
                        to join the movement to ensure prompt and effective responses
                    </Span>
                    <Span color={COLOR.primaryLight} weight={FONT_WEIGHT.SEMIBOLD}>
                    &nbsp;during medical emergencies.
                    </Span>
                </Text>
            </motion.div>
            <div className='rounded-md overflow-clip relative'>
                {isMuted && (
                    <div className='absolute top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.4)]'>
                        <Flex fill direction='column' justify={JUSTIFY_CONTENT.center}>
                            <Button
                                onClick={() => videoPlayHandler()}
                                type='flex justify-center z-[5]'
                                align={TEXT_ALIGN.center}
                                label={(
                                    <img src={files.images.videoVolumeOff} alt='Volume Off' />
                                )}
                            />
                        </Flex>
                    </div>
                )}
                <video
                    ref={videoRef}
                    loop
                    className='rounded-sm z-[1]'
                    muted={!!isMuted}
                    onClick={() => videoPlayHandler()}
                    playsInline
                >
                    <source src={files.videos.saveANumberVideo} />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Flex>
    );
};

export default VideoSection;
