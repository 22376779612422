import { ApiResponse } from 'apisauce';
import { IDownloadEbookApiParam, IDownloadEbookApiResponse } from 'entities/pdf';
import { GatewayResponse } from './types/types';

import IPdfGateway from './PdfBase';

export default class PdfGateway extends IPdfGateway {
    async downloadEbook(params: IDownloadEbookApiParam): GatewayResponse<IDownloadEbookApiResponse> {
        const response: ApiResponse<IDownloadEbookApiResponse> = await this.api.post('pdf/download', params);
        return this.process(response);
    }
}
