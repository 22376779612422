import React from 'react';

interface SectionComponentProps {
    children: React.ReactNode;
    parentBg? : React.ComponentProps<'div'>['className'];
    containerBg? : React.ComponentProps<'div'>['className'];
}

const Section = ({
    children,
    parentBg,
    containerBg,
}:SectionComponentProps):JSX.Element => {
    return (
        <section className={parentBg}>
            <div className={`shadow max-w-[550px] mx-auto ${containerBg}`}>
                {children}
            </div>
        </section>
    );
};

Section.defaultProps = {
    parentBg: '',
    containerBg: '',
};

export default Section;
