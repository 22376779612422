import React from 'react';

import { COLOR, FONT_WEIGHT } from 'entities/tailwind';

const FONT_WEIGHT_VARIANT: Record<FONT_WEIGHT, string> = {
    [FONT_WEIGHT.THIN]: 'font-thin',
    [FONT_WEIGHT.EXTRALIGHT]: 'font-extralight',
    [FONT_WEIGHT.LIGHT]: 'font-light',
    [FONT_WEIGHT.NORMAL]: 'font-normal',
    [FONT_WEIGHT.MEDIUM]: 'font-medium',
    [FONT_WEIGHT.SEMIBOLD]: 'font-semibold',
    [FONT_WEIGHT.BOLD]: 'font-bold',
    [FONT_WEIGHT.EXTRABOLD]: 'font-extrabold',
    [FONT_WEIGHT.GIGABOLD]: 'font-black',
};

const FONT_COLOR_VARIANT: Record<COLOR, string> = {
    [COLOR.primary]: 'text-smcv-primary',
    [COLOR.primaryLight]: 'text-smcv-primary-light',
    [COLOR.secondary]: 'text-smcv-secondary',
    [COLOR.tertiary]: 'text-smcv-tertiary',
    [COLOR.black]: 'text-black',
    [COLOR.white]: 'text-white',
    [COLOR.cardDesc]: 'text-smcv-card-desc',
};

interface SpanComponentProps {
    color?: COLOR;
    weight?: FONT_WEIGHT;
    children: React.ReactNode;
    italic?: boolean;
}

const Span = ({ color = COLOR.tertiary, weight = FONT_WEIGHT.NORMAL, italic = false, children }: SpanComponentProps):JSX.Element => {
    const newClass = `${FONT_WEIGHT_VARIANT[weight]} ${FONT_COLOR_VARIANT[color]} ${italic && 'italic'}`;

    return (
        <span className={newClass}>{children}</span>
    );
};

Span.defaultProps = {
    weight: FONT_WEIGHT.NORMAL,
    color: COLOR.cardDesc,
    italic: false,
};

export default Span;
