import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AnimatePresence, motion, useScroll } from 'framer-motion';
import { RWebShare } from 'react-web-share';

import { RootState } from 'redux/store';
import Selectors from 'redux/Selectors';

import { SPACING } from 'entities/tailwind';

import Flex from 'components/Flex';
import Section from 'components/Section';
import EBook from './EBook';
import HomeFooter from './HomeFooter';
import HomeNavbar from './HomeNavbar';
import InformationCards from './Information';
import QRModal from './Modal/QRModal';
import EbookModal from './Modal/EbookModal';
import VideoSection from './VideoSection';
import ThankYou from './ThankYou';

interface HomeScreenProps {
    isEbookModalOpen: boolean;
    isQrModalOpen: boolean;
    test: boolean;
}

const HomeScreen = ({
    isEbookModalOpen,
    isQrModalOpen,
    test,
}: HomeScreenProps): JSX.Element => {
    const divRef = useRef<HTMLDivElement>(null);
    const shareButtonRef = useRef<HTMLButtonElement>(null);
    const scroll = useScroll({
        target: divRef,
    });

    const onShare = () => {
        if (shareButtonRef.current) {
            shareButtonRef.current.click();
        }
    };

    return (
        <div ref={divRef}>
            <AnimatePresence>
                <motion.main className='pt-[237px]'>
                    <HomeNavbar refScrollY={scroll.scrollYProgress} shareButtonOnClick={onShare} />
                    <div className='min-h-[400px] top-0 max-w-[550px] absolute m-auto left-0 right-0 bg-white' />
                    <Section containerBg='bg-white'>
                        <Flex xPadding={SPACING.large} direction='column' stagger salt='relative'>
                            <InformationCards refScrollY={scroll.scrollYProgress} />
                            <VideoSection />
                        </Flex>
                    </Section>
                    <Section parentBg='bg-[#833635]' containerBg='bg-smcv-primary-light'>
                        <Flex xPadding={SPACING.large} direction='column' stagger>
                            <EBook />
                        </Flex>
                    </Section>
                    <Section containerBg='bg-white'>
                        <ThankYou shareButtonOnClick={onShare} />
                    </Section>
                    <Section parentBg='bg-[#833635]' containerBg='bg-smcv-primary-light'>
                        <HomeFooter />
                    </Section>
                    {isQrModalOpen && <QRModal />}
                    {isEbookModalOpen && <EbookModal />}
                    <div className='hidden'>
                        <RWebShare
                            data={{
                                text: 'Save A Number, Save A Life',
                                url: 'https://sunmedvelocity.com/saveanumbersavealife',
                                title: 'Save A Number, Save A Life',
                            }}
                        >
                            <button ref={shareButtonRef} type='button'>Share</button>
                        </RWebShare>
                    </div>
                </motion.main>
            </AnimatePresence>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    isQrModalOpen: Selectors.uiGetIsOpenContactModal(state),
    isEbookModalOpen: Selectors.uiIsEbookModalOpen(state),
});

export default connect(mapStateToProps)(HomeScreen);
