import React from 'react';
import { COLOR, FONT_SIZE, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';

import { motion } from 'framer-motion';

import Footer from 'components/Footer';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Span from 'components/Span';

const HomeFooter = ():JSX.Element => {
    const anchorTagsData = [
        {
            id: 'anchortags-privacy-policy',
            name: 'Privacy Policy',
            link: 'https://www.sunmedvelocity.com.my/en/privacy-policy/',
        },
        {
            id: 'anchortags-terms-conditions',
            name: 'Terms & Conditions',
            link: '#',
        },
        {
            id: 'anchortags-sitemap',
            name: 'Sitemap',
            link: '#',
        },
    ];
    const renderAnchorTags = (id:string, str:string, link = '#') => {
        return (
            <motion.a
                key={id}
                href={link}
                whileHover={
                    {
                        opacity: 0.5,
                    }
                }
            >
                <Text align={TEXT_ALIGN.center} size={FONT_SIZE.smallest}>
                    <Span color={COLOR.white}>{str}</Span>
                </Text>
            </motion.a>
        );
    };

    return (
        <Footer>
            <Flex noPadding gap={SPACING.extraSmall} direction='column' justify={JUSTIFY_CONTENT.center}>
                <Text size={FONT_SIZE.smallest} align={TEXT_ALIGN.center}>
                    <Span color={COLOR.white}>&copy; 2023 Sunway Medical Centre Velocity All Right Reserved.</Span>
                </Text>
                <Flex noPadding justify={JUSTIFY_CONTENT.center} gap={SPACING.extraSmall}>
                    {anchorTagsData.map((anchor) => {
                        return renderAnchorTags(anchor.id, anchor.name, anchor.link);
                    })}
                </Flex>
            </Flex>
        </Footer>
    );
};

export default HomeFooter;
