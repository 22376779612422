import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ContactReduxState } from './types';

const initialState: ContactReduxState = {
    actions: {
        saveContact: false,
        savedContactCounter: false,
    },
    savedCount: 0,
    error: {
        saveContact: '',
        savedContactCounter: '',
    },
};

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        contactSaveContactAttempt: (state) => {
            state.actions.saveContact = true;
            state.error.saveContact = '';
        },
        contactSaveContactFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.saveContact = false;
            if (action.payload) {
                state.error.saveContact = action.payload;
            }
        },
        contactSaveContactSuccess: (state) => {
            state.actions.saveContact = false;
            state.error.saveContact = '';
        },
        contactGetSavedContactCounterAttempt: (state) => {
            state.actions.savedContactCounter = true;
            state.error.savedContactCounter = '';
        },
        contactGetSavedContactCounterFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.savedContactCounter = false;
            if (action.payload) {
                state.error.savedContactCounter = action.payload;
            }
        },
        contactGetSavedContactCounterSuccess: (state, action:PayloadAction<number>) => {
            state.actions.savedContactCounter = false;
            state.error.savedContactCounter = '';

            if (action.payload) {
                state.savedCount = action.payload;
            }
        },
    },
});

export type ContactState = typeof initialState;

export default {
    actions: contactSlice.actions,
    reducers: contactSlice.reducer,
};
