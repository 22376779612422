import { fork } from 'redux-saga/effects';

import api from 'api';

import auth from './auth';
import pdf from './pdf';
import contact from './contact';

import { SagaForkReturnType } from './types';

const apiInstance = api;

export default function* root(): SagaForkReturnType {
    yield fork(auth(apiInstance.auth).rootSaga);
    yield fork(contact(apiInstance.contact).rootSaga);
    yield fork(pdf(apiInstance.pdf).rootSaga);
}
