import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import files from 'assets/files';
import { ISMCVContactInfo } from 'entities/startup';
import { UIReduxState } from './types';

const initialState: UIReduxState = {
    isOpenContactModal: false,
    isEbookModalOpen: false,
    contactVCard: '',
    footerIsInView: false,
    contactInformation: {
        name: 'SMCV Emergency Dept',
        phone: '0397729111',
        email: 'smcv-enquiry@sunway.com.my',
        photo: `${files.images.smcvLogo}`,
    },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        uiSetIsOpenContactModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenContactModal = action.payload;
        },
        uiGenerateContactVCard: (state) => {
            const contact:ISMCVContactInfo = state.contactInformation;
            const vcard = `BEGIN:VCARD\nVERSION:4.0\nFN:${contact.name}\nTEL;TYPE=work,voice:${contact.phone}\nEMAIL:${contact.email}\nPHOTO;VALUE=URL:${contact.photo}\nEND:VCARD`;
            state.contactVCard = vcard;
        },
        uiSetEbookModalOpen: (state, action:PayloadAction<boolean>) => {
            state.isEbookModalOpen = action.payload;
        },
        uiDisplayFooterButtons: (state, action:PayloadAction<boolean>) => {
            state.footerIsInView = action.payload;
        },
    },
});

export type UiState = typeof initialState;

export default {
    actions: uiSlice.actions,
    reducers: uiSlice.reducer,
};
