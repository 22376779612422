import React from 'react';

import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import Flex from 'components/Flex';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Span from 'components/Span';
import Button from 'components/Button';
import Radio from 'components/Radio';

import files from 'assets/files';

import { ALIGN_ITEMS, COLOR, FONT_SIZE, FONT_WEIGHT, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';

import { EbookLanguageEnum } from 'entities/base';
import { motion } from 'framer-motion';

interface EbookModalProps {
    setIsOpen: (param:boolean) => void;
    downloadEbook: (param: EbookLanguageEnum) => void;
    isOpen: boolean;
    downloadEbookIsLoading: boolean;
}

const EbookModal = ({
    setIsOpen,
    isOpen,
    downloadEbook,
    downloadEbookIsLoading,
}: EbookModalProps):JSX.Element => {
    const [placeholder, setPlaceholder] = React.useState<string>(files.images.placeholderEn);
    const [ebookLanguage, setEbookLanguage] = React.useState<EbookLanguageEnum>(EbookLanguageEnum.english);

    const ebookLanguageHandler = (language:EbookLanguageEnum) => {
        const ebookImage = {
            english: files.images.placeholderEn,
            chinese: files.images.ebookCN,
            malay: files.images.ebookBM,
        };

        switch (language) {
            case EbookLanguageEnum.english:
                setPlaceholder(ebookImage.english);
                break;
            case EbookLanguageEnum.malay:
                setPlaceholder(ebookImage.malay);
                break;
            case EbookLanguageEnum.chinese:
                setPlaceholder(ebookImage.chinese);
                break;
            default:
                setPlaceholder(ebookImage.english);
                break;
        }

        setEbookLanguage(language);
    };

    return (
        <Modal onClick={() => setIsOpen(!isOpen)}>
            <Flex
                direction='column'
                fill
                align={ALIGN_ITEMS.center}
                justify={JUSTIFY_CONTENT.center}
                gap={SPACING.small}
            >
                <Text align={TEXT_ALIGN.center} size={FONT_SIZE.small}>
                    <Span weight={FONT_WEIGHT.SEMIBOLD}>
                        DOWNLOAD E-BOOKLET
                    </Span>
                </Text>
                <Flex direction='column' noPadding justify={JUSTIFY_CONTENT.center} gap={SPACING.none}>
                    <motion.div
                        key={placeholder}
                        className='min-h-[180px]'
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                            scale: 0,
                            opacity: 0,
                        }}
                    >
                        <img
                            src={placeholder}
                            alt='E-book Placeholder'
                            className='max-h-[180px]'
                            style={{
                                transition: 'all .5s',
                            }}
                        />
                    </motion.div>
                    <Text size={FONT_SIZE.smallest}><Span>Select your language</Span></Text>
                </Flex>
                <Flex
                    justify={JUSTIFY_CONTENT.center}
                    xPadding={SPACING.none}
                    yPadding={SPACING.none}
                    gap={SPACING.none}
                >
                    <Radio
                        onClick={() => ebookLanguageHandler(EbookLanguageEnum.english)}
                        label='English'
                        value={EbookLanguageEnum.english}
                        currentValue={ebookLanguage}
                    />
                    <Radio
                        onClick={() => ebookLanguageHandler(EbookLanguageEnum.chinese)}
                        label='Chinese'
                        value={EbookLanguageEnum.chinese}
                        currentValue={ebookLanguage}
                    />
                    <Radio
                        onClick={() => ebookLanguageHandler(EbookLanguageEnum.malay)}
                        label='BM'
                        value={EbookLanguageEnum.malay}
                        currentValue={ebookLanguage}
                    />
                </Flex>
                <Button
                    isLoading={downloadEbookIsLoading}
                    onClick={() => downloadEbook(ebookLanguage)}
                    label={(
                        <Flex
                            gap={SPACING.extraSmall}
                            justify={JUSTIFY_CONTENT.center}
                            xPadding={SPACING.none}
                            yPadding={SPACING.none}
                        >
                            <img
                                src={files.images.downloadEbookWhite}
                                alt='Download E-Book'
                                className='h-4'
                            />
                            <Span weight={FONT_WEIGHT.SEMIBOLD} color={COLOR.white}>
                                Download
                            </Span>
                        </Flex>
                    )}
                />
            </Flex>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    downloadEbookIsLoading: Selectors.pdfDownloadEbookAttempting(state),
    isOpen: Selectors.uiIsEbookModalOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsOpen: (param: boolean) => dispatch(Actions.uiSetEbookModalOpen(param)),
    downloadEbook: (param: EbookLanguageEnum) => dispatch(Actions.pdfDownloadEbookAttempt(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EbookModal);
