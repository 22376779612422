import authSlice from 'redux/slices/auth';
import uiSlice from 'redux/slices/ui';
import pdfSlice from 'redux/slices/pdf';
import contactSlice from 'redux/slices/contact';

export default {
    ...authSlice.actions,
    ...uiSlice.actions,
    ...pdfSlice.actions,
    ...contactSlice.actions,
};
