/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { JUSTIFY_CONTENT, SPACING } from 'entities/tailwind';
import Flex from './Flex';
import Span from './Span';

interface RadioComponentProps<T> {
    onClick: (param:T) => void;
    label: string;
    value: T;
    currentValue: T;
}

const Radio = <T, >({
    onClick,
    label,
    value,
    currentValue,
}:RadioComponentProps<T>):JSX.Element => {
    return (
        <Flex gap={SPACING.extraSmall} justify={JUSTIFY_CONTENT.center} yPadding={SPACING.none} xPadding={SPACING.small}>
            <input
                id={`radio-${value}`}
                className='appearance-none checked:bg-smcv-primary focus:outline-none hover:focus:bg-smcv-primary hover:focus:border-smcv-primary-light text-smcv-primary focus-within:ring-4 focus-within:ring-transparent'
                type='radio'
                checked={currentValue === value}
                onChange={() => onClick(value)}
            />
            <label htmlFor={`radio-${value}`}><Span>{label}</Span></label>
        </Flex>
    );
};

export default Radio;
