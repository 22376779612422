import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';

import { RootState } from 'redux/store';
import Selectors from 'redux/Selectors';

import { ALIGN_ITEMS, COLOR, FONT_WEIGHT, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';

import Flex from 'components/Flex';
import Text from 'components/Text';
import Span from 'components/Span';
import Numbers from './Numbers';

interface CounterProps {
    savedContactCounter: number;
    passedScreenHeight: boolean;
}

const Counter = (props: CounterProps):JSX.Element => {
    const {
        savedContactCounter,
        passedScreenHeight,
    } = props;

    const containerStyle = passedScreenHeight ? '' : 'relative top-[12px]';

    return (
        <Flex direction='column' align={ALIGN_ITEMS.end} justify={JUSTIFY_CONTENT.end} noPadding gap={SPACING.none} salt={`self-end z-[1] ${containerStyle}`}>
            <Flex
                gap={SPACING.none}
                noPadding
                style={{
                    flexDirection: passedScreenHeight ? 'row-reverse' : 'column',
                }}
            >
                <div
                    className='flex flex-col items-center justify-start'
                    style={{
                        position: 'relative',
                        alignSelf: passedScreenHeight ? 'baseline' : 'end',
                        top: passedScreenHeight ? '7px' : '',
                        left: passedScreenHeight ? '3px' : '',
                    }}
                >
                    <motion.div
                        className='px-1 py-[2px] text-white uppercase text-[6px] font-bold rounded-sm relative z-[1]'
                        animate={{
                            backgroundColor: ['rgba(227, 45, 44, 1)', 'rgba(227, 45, 44,.9)', '#e64343'],
                        }}
                        transition={{ repeat: Infinity, duration: 2, repeatType: 'reverse' }}
                    >
                        <motion.div
                            className='absolute inset-0 min-h-[10px] min-w-[10px] bg-smcv-primary rounded-sm z-[0]'
                            animate={{
                                scaleX: [1, 1.5],
                                scaleY: [1, 1.7],
                                opacity: [0.8, 0],
                                zIndex: -1,
                            }}
                            transition={{ repeat: Infinity, duration: 2, ease: 'easeOut' }}
                        />
                        <div className='z-[100]'>Live</div>
                    </motion.div>

                </div>
                <Flex
                    noPadding
                    fill={false}
                    salt='self-end'
                    direction='column'
                    gap={SPACING.none}
                    align={ALIGN_ITEMS.start}
                >
                    <Numbers count={savedContactCounter} />
                    <Text size='text-[7px]' salt='max-w-[120px] font-bold self-end' align={TEXT_ALIGN.end}>
                        <Span color={COLOR.white} weight={FONT_WEIGHT.BOLD}>
                            Individuals who have saved
                            our A&E Dept. Hotline
                        </Span>
                    </Text>
                </Flex>
            </Flex>
        </Flex>

    );
};

const mapStateToProps = (state: RootState) => ({
    savedContactCounter: Selectors.contactGetSavedContactCounter(state),
});

export default connect(mapStateToProps)(Counter);
