import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import { motion, useInView } from 'framer-motion';
import { RWebShare } from 'react-web-share';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import Flex from 'components/Flex';
import Span from 'components/Span';
import Text from 'components/Text';
import Button from 'components/Button';

import { ALIGN_ITEMS, BUTTON, COLOR, FONT_SIZE, FONT_WEIGHT, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';
import { ISMCVContactInfo } from 'entities/startup';
import { PlatformEnum } from 'entities/base';

import Motion from 'lib/Motion';

import files from 'assets/files';

interface ThankYouProps {
    setFooterIsInView: (param: boolean) => void;
    saveContact: () => void;
    displayContactModal: (param: boolean) => void;
    isFooterInView: boolean;
    smcvContactInfo: ISMCVContactInfo;
    vcardUrl: string;
    os: PlatformEnum;
    shareButtonOnClick: () => void;
}

const ThankYou = ({
    isFooterInView,
    setFooterIsInView,
    smcvContactInfo,
    vcardUrl,
    os,
    saveContact,
    displayContactModal,
    shareButtonOnClick,
}:ThankYouProps):JSX.Element => {
    const buttonsContainerRef = useRef<HTMLDivElement>(null);

    const shareButtonRef = useRef<HTMLButtonElement>(null);

    const isInView = useInView(buttonsContainerRef);

    const onClickSaveContact = () => {
        saveContact();

        if (os === PlatformEnum.android) {
            return window.open(`tel:${smcvContactInfo.phone}`, '_self');
        }

        if (os === PlatformEnum.ios) {
            const blob = new Blob([vcardUrl], { type: 'text/vcard' });
            const url = URL.createObjectURL(blob);
            const newLink = document.createElement('a');
            newLink.download = `${smcvContactInfo.name}.vcf`;
            newLink.textContent = smcvContactInfo.name;
            newLink.href = url;
            return newLink.click();
        }

        return displayContactModal(true);
    };

    const shareLink = () => {
        const shareUrl = 'https://sunmedvelocity.com/saveanumbersavealife';
        const canShare = 'share' in window.navigator;

        if (os === PlatformEnum.ios || os === PlatformEnum.android) {
            if (canShare) {
                navigator.share({
                    title: 'SMCV',
                    text: 'Hello, please come visit my website',
                    url: shareUrl,
                });
            }
        }
    };

    useEffect(() => {
        const container = buttonsContainerRef.current;
        if (container) {
            if (isInView) {
                const displayButtonContainer = setTimeout(() => setFooterIsInView(true), 1);
                return () => {
                    clearTimeout(displayButtonContainer);
                };
            }

            const hideButtonContainer = setTimeout(() => setFooterIsInView(false), 1);
            return () => {
                return () => {
                    clearTimeout(hideButtonContainer);
                };
            };
        }
        return undefined;
    }, [isInView]);

    return (
        <Flex
            direction='column'
            justify={JUSTIFY_CONTENT.center}
            salt='min-h-[440px] bg-[url("../../assets/files/eclipseCorner.png")] bg-cover bg-center'
        >
            <motion.div variants={Motion.textVariant(0.6)}>
                <Flex direction='column' gap={SPACING.none} noPadding>
                    <Text align={TEXT_ALIGN.center} size={FONT_SIZE.small} salt=' leading-[1.6rem]'>
                        <Span weight={FONT_WEIGHT.MEDIUM}>
                            Thank you for being a part of
                        </Span>
                    </Text>
                    <Text salt='uppercase whitespace-nowrap' size='text-[24px] xs:text-[20px]' align={TEXT_ALIGN.center}>
                        <Span weight={FONT_WEIGHT.BOLD} color={COLOR.primaryLight}>
                            &quot;Save a number, save a life&quot;
                        </Span>
                    </Text>
                    <Text size={FONT_SIZE.smallest} align={TEXT_ALIGN.center} salt='mt-2 max-w-[250px]'>
                        <Span weight={FONT_WEIGHT.LIGHT}>
                            The simple yet impactful act that can make a
                            difference in someone’s life.
                        </Span>
                    </Text>
                </Flex>
            </motion.div>
            <div ref={buttonsContainerRef}>
                <Flex direction='column' noPadding stagger>
                    <motion.div
                        animate={isFooterInView ? 'visible' : 'hidden'}
                        variants={Motion.basicEaseIn}
                    >
                        <Button
                            onClick={() => onClickSaveContact()}
                            label={(
                                <Flex noPadding gap={SPACING.extraSmall} align={ALIGN_ITEMS.center}>
                                    <Text size={FONT_SIZE.smaller}>
                                        <Span color={COLOR.white} weight={FONT_WEIGHT.SEMIBOLD}>
                                            Save Contact
                                        </Span>
                                    </Text>
                                    <SVG
                                        src={files.svg.footerPhone}
                                        fill='#fff'
                                        width={25}
                                        height={25}
                                        className='rotate-[270deg] relative top-[1px]'
                                    />
                                </Flex>
                            )}
                        />
                    </motion.div>

                    <Button
                        type={BUTTON.clear}
                        onClick={() => shareButtonOnClick()}
                        label={(
                            <Flex
                                yPadding={SPACING.none}
                                xPadding={SPACING.extraLarge}
                                gap={SPACING.extraSmall}
                                align={ALIGN_ITEMS.center}
                                justify={JUSTIFY_CONTENT.center}
                                salt='border-[1px] border-smcv-primary px-11 py-2 rounded-full'
                            >
                                <Text size={FONT_SIZE.smaller}>
                                    <Span color={COLOR.primary} weight={FONT_WEIGHT.SEMIBOLD}>
                                        Share
                                    </Span>
                                </Text>
                                <SVG
                                    src={files.svg.footerShare}
                                    fill='rgba(255,0,0)'
                                    width={25}
                                    height={25}
                                    className='relative top-[1px]'
                                />
                            </Flex>
                        )}
                    />
                </Flex>
            </div>
        </Flex>
    );
};

const mapStateToProps = (state: RootState) => ({
    isFooterInView: Selectors.uiIsFooterInView(state),
    smcvContactInfo: Selectors.uiSMCVContactInfo(state),
    vcardUrl: Selectors.uiGetContactVCard(state),
    os: Selectors.authUserPlatform(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    displayContactModal: (param: boolean) => dispatch(Actions.uiSetIsOpenContactModal(param)),
    setFooterIsInView: (param: boolean) => dispatch(Actions.uiDisplayFooterButtons(param)),
    saveContact: () => dispatch(Actions.contactSaveContactAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
