import cardEclipseJPG from './cardEclipseCorner.jpg';
import cardEclipsePNG from './eclipseCorner.png';
import cardOneBG from './cardOneBackground.png';
import cardOneBGWithFooter from './cardOneWithFooter.png';
import cardTwoBG from './cardTwoBackground.png';
import cardTwoBGWithFooter from './cardTwoWithFooter.png';
import ebookHeader from './ebook-header.png';
import heartRate from './grayscaleHeartRate.png';
import people from './people.png';
import ebookENG from './ebook_ENG.png';
import ebookCN from './ebook_CN.png';
import ebookBM from './ebook_BM.png';
import downloadEbook from './downloadArrow.png';
import downloadEbookWhite from './downloadArrowWhite.png';
import placeholderEn from './placeholder_en.png';
import placeholderCn from './placeholder_en.png';
import placeholderMy from './placeholder_en.png';
import saveANumber from './videoImage.png';
import videoVolumeOff from './video_volume_off.png';
import sharePNG from './share.png';
import phonePNG from './phone.png';

import share from './share.svg';
import cancel from './cancel.svg';
import cancelModal from './cancel-modal.svg';
import footerShare from './footerShare.svg';
import footerPhone from './footerPhone.svg';

import saveANumberVideo from './vid_placeholder.mp4';

import smcvLogo from './smcv-logo.jpeg';
import videoHeading from './smcv_videoHeader.png';

const images = {
    cardEclipseJPG,
    cardEclipsePNG,
    cardOneBG,
    cardOneBGWithFooter,
    cardTwoBG,
    cardTwoBGWithFooter,
    heartRate,
    people,
    ebookHeader,
    ebookENG,
    downloadEbook,
    downloadEbookWhite,
    placeholderEn,
    placeholderCn,
    placeholderMy,
    saveANumber,
    smcvLogo,
    videoVolumeOff,
    sharePNG,
    phonePNG,
    ebookCN,
    ebookBM,
    videoHeading,
};

const svg = {
    share,
    cancel,
    cancelModal,
    footerShare,
    footerPhone,
};

const videos = {
    saveANumberVideo,
};

const pdf = {
    ebookEn: 'enbook',
    ebookCn: 'enbook',
    ebookMy: 'enbook',
};

export default {
    images,
    videos,
    pdf,
    svg,
};
