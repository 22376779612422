import React from 'react';
import { motion } from 'framer-motion';

import Flex from 'components/Flex';
import Text from 'components/Text';
import Span from 'components/Span';
import Button from 'components/Button';

import { ALIGN_ITEMS, BUTTON, COLOR, FONT_SIZE, FONT_WEIGHT, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';

import files from 'assets/files';

import Motion from 'lib/Motion';

import { AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import { connect } from 'react-redux';

interface EbookScreenProps {
    setIsOpen: (param: boolean) => void;
}

const EBook = ({
    setIsOpen,
}: EbookScreenProps):JSX.Element => {
    return (
        <Flex fill direction='column' xPadding={SPACING.none}>
            <Flex noPadding gap={SPACING.small} justify={JUSTIFY_CONTENT.start}>
                <Flex direction='column' align={ALIGN_ITEMS.start} noPadding gap={SPACING.extraSmall} salt='leading-10 max-w-max flex-nowrap'>
                    <motion.div variants={Motion.textVariant(0.6)}>
                        <Text size={FONT_SIZE.larger}>
                            <Span weight={FONT_WEIGHT.BOLD} color={COLOR.white}>2X or 3X</Span>
                        </Text>
                    </motion.div>
                    <motion.div variants={Motion.textVariant(0.6)}>
                        <Text size={FONT_SIZE.large} salt='xs:text-[23px]'>
                            <Span weight={FONT_WEIGHT.BOLD} color={COLOR.white}>
                                Survival Chance
                            </Span>
                        </Text>
                    </motion.div>
                </Flex>
                <motion.div variants={Motion.slideIn('up', 'smooth', 0.3, 1)}>
                    <img src={files.images.ebookHeader} alt='E-book' />
                </motion.div>
            </Flex>
            <Flex noPadding direction='column' gap={SPACING.extraSmall} align={ALIGN_ITEMS.start} justify={JUSTIFY_CONTENT.start} salt='max-w-[90%] self-start'>
                <motion.div variants={Motion.textVariant(0.6)}>
                    <Text size={FONT_SIZE.smaller}>
                        <Span color={COLOR.white}>
                            Providing first aid within the first few minutes can double or triple survival chances.
                        </Span>
                    </Text>
                </motion.div>
                <motion.div variants={Motion.textVariant(0.6)}>
                    <Text size='text-[8px]'>
                        <Span color={COLOR.white} italic>
                            *Source:
                            <br />
                            &quot;Three Things You May not Know About CPR&quot;, CDC, October 22, 2021&quot;.
                        </Span>
                    </Text>
                </motion.div>

            </Flex>
            <Flex direction='column' align={ALIGN_ITEMS.center} gap={SPACING.small} justify={JUSTIFY_CONTENT.center} salt='min-h-[300px]' xPadding={SPACING.none} yPadding={SPACING.none}>
                <Button
                    salt='p-0'
                    type={BUTTON.clear}
                    onClick={() => null}
                    label={
                        <img src={files.images.placeholderEn} alt='ENG' />
                    }
                />
                <div className='max-w-[400px]'>
                    <Text align={TEXT_ALIGN.center} size={FONT_SIZE.smallest}>
                        <Span color={COLOR.white} weight={FONT_WEIGHT.SEMIBOLD}>
                            Our First Aid E-Booklet covers
                            <br />
                            the most common medical
                            emergencies and
                            <br />
                            how you can potentially save a life with
                            effective
                            <br />
                            & timely first aid assistance.
                        </Span>
                    </Text>
                </div>
                <div>
                    <Text size={FONT_SIZE.smallest}>
                        <Span weight={FONT_WEIGHT.LIGHT} color={COLOR.white}>Download our First-Aid E-Booklet here.</Span>
                    </Text>
                </div>
                <motion.div variants={Motion.slideIn('up', 'smooth', 0.5, 1)}>
                    <Button
                        onClick={() => setIsOpen(true)}
                        type={BUTTON.secondary}
                        label={(
                            <Flex justify={JUSTIFY_CONTENT.center} noPadding gap={SPACING.extraSmall}>
                                <img
                                    src={files.images.downloadEbook}
                                    alt='Download E-Book'
                                    className='h-4'
                                />
                                <Span weight={FONT_WEIGHT.SEMIBOLD} color={COLOR.primaryLight}>
                                    Download
                                </Span>
                            </Flex>
                        )}
                    />
                </motion.div>
            </Flex>
        </Flex>

    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsOpen: (param: boolean) => dispatch(Actions.uiSetEbookModalOpen(param)),
});

export default connect(null, mapDispatchToProps)(EBook);
