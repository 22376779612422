import config from 'config';

import AuthGateway from './Auth';
import PdfGateway from './Pdf';
import ContactGateway from './Contact';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const pdf = new PdfGateway(baseUrl);
const contact = new ContactGateway(baseUrl);

export default {
    auth,
    pdf,
    contact,
};
