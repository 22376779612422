import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { MotionValue, motion, useTransform } from 'framer-motion';

import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';

import files from 'assets/files';

import { ALIGN_ITEMS, COLOR, FONT_SIZE, FONT_WEIGHT, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';
import { ISMCVContactInfo } from 'entities/startup';
import { PlatformEnum } from 'entities/base';

import Flex from 'components/Flex';
import Button from 'components/Button';
import Text from 'components/Text';
import Span from 'components/Span';
import Counter from './Counter';

interface HomeNavbarProps {
    setIsOpenContactModal(data: boolean): void;
    saveContact(): void;
    shareButtonOnClick: () => void;
    smcvContactInfo: ISMCVContactInfo;
    vcardUrl: string;
    userOS: PlatformEnum;
    isFooterInView: boolean;
    refScrollY: MotionValue<number>;
}

const HomeNavbar = (props: HomeNavbarProps):JSX.Element => {
    const {
        setIsOpenContactModal,
        saveContact,
        smcvContactInfo,
        vcardUrl,
        userOS,
        isFooterInView,
        refScrollY,
        shareButtonOnClick,
    } = props;

    const [isMoreThan100vh, setIsMoreThan100vh] = useState(false);
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const scale = useTransform(refScrollY, [0, 0.04], [1, 0]);
    const height = useTransform(refScrollY, [0, 0.06], ['50px', '0px']);
    const top = useTransform(refScrollY, [0, 0.05], ['0px', '-8px']);

    // Button Container
    const bottom = useTransform(refScrollY, [0, 0.04], ['0px', '4px']);

    const buttonContainerVariant = {
        open: {
            top: 0,
        },
        closed: {
            top: '-5px',
        },
    };

    const buttonVariant = {
        hidden: {
            opacity: 1,
            scale: 1,
        },
        visible: {
            opacity: 0.0,
            scale: 0.0,
        },
    };

    const onClickSaveContact = () => {
        saveContact();

        if (userOS === PlatformEnum.android) {
            return window.open(`tel:${smcvContactInfo.phone}`, '_self');
        }

        if (userOS === PlatformEnum.ios) {
            const blob = new Blob([vcardUrl], { type: 'text/vcard' });
            const url = URL.createObjectURL(blob);
            const newLink = document.createElement('a');
            newLink.download = `${smcvContactInfo.name}.vcf`;
            newLink.textContent = smcvContactInfo.name;
            newLink.href = url;
            return newLink.click();
        }

        return setIsOpenContactModal(true);
    };

    const handleScroll = () => {
        const currentPosition = window.scrollY || document.documentElement.scrollTop;
        setScrollPosition(currentPosition);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // 25px is a height breakpoint to hide the header above Save Contact button
        if (scrollPosition >= 25) {
            const minimizeNavbar = setTimeout(() => setIsMoreThan100vh(true), 1);
            return () => {
                clearTimeout(minimizeNavbar);
            };
        }

        const maximiseNavbar = setTimeout(() => setIsMoreThan100vh(false), 1);
        return () => {
            clearTimeout(maximiseNavbar);
        };
    }, [scrollPosition]);

    return (
        <div className='fixed top-0 left-0 right-0 z-[100] max-w-[550px] mx-auto'>
            <Flex fill justify={JUSTIFY_CONTENT.between} salt='relative bg-white' yPadding={SPACING.extraSmall}>
                <img src={files.images.smcvLogo} alt='Sunway Medical Centre Velocity' className='max-h-[50px]' />
                <img src={files.images.saveANumber} alt='Save A Number, Save A Life' className='max-h-[30px]' />
            </Flex>
            <div className='bg-smcv-primary min-h-[1rem] ' />
            <motion.div animate={isMoreThan100vh ? 'closed' : 'open'} className='relative z-[-1]' style={{ top }}>
                <Flex
                    align={ALIGN_ITEMS.stretch}
                    yPadding={isMoreThan100vh ? SPACING.extraSmall : SPACING.default}
                    salt='bg-[url("../../assets/files/navbarBG.jpg")] bg-cover bg-bottom rounded-b-[30px] min-h-[50px] relative '
                >
                    <div className=' bg-[url("../../assets/files/navbarOverlay.png")] opacity-100 top-0 bottom-0 right-0 left-0 absolute bg-cover bg-center z[0] rounded-b-[30px]' />
                    <div className='top-0 bottom-0 right-0 left-0 absolute bg-black bg-clip-border rounded-b-[30px] opacity-[40%]' />
                    <Flex direction='column' noPadding align={ALIGN_ITEMS.baseline} salt={`z-[1] ${isMoreThan100vh ? 'self-center' : ''}`}>

                        <motion.div style={{
                            scale,
                            height,
                        }}
                        >
                            <Text size='text-smaller xs:text-[13.5px]' salt='max-w-[170px] uppercase ' align={TEXT_ALIGN.start}>
                                <Span weight={FONT_WEIGHT.EXTRABOLD} color={COLOR.white}>
                                    Save the number,
                                    <br />
                                    Be the change that
                                    <br />
                                    can save lives.
                                </Span>
                            </Text>
                        </motion.div>

                        <motion.div variants={buttonContainerVariant} animate={isMoreThan100vh ? 'closed' : 'open'} className='relative z-[-1]'>
                            <motion.div
                                variants={buttonVariant}
                                animate={isFooterInView ? 'visible' : 'hidden'}
                                style={{ bottom }}
                                className='relative'
                            >
                                <Flex noPadding gap={SPACING.extraSmall} salt='flex-grow'>
                                    <Button
                                        onClick={() => onClickSaveContact()}
                                        type='py-[.5rem] px-6 max-w-max bg-smcv-primary whitespace-nowrap'
                                        label={(
                                            <Text size={FONT_SIZE.smallest} align={TEXT_ALIGN.center}>
                                                <Span color={COLOR.white}>
                                                    Save contact
                                                </Span>
                                            </Text>
                                        )}
                                    />

                                    <Button
                                        onClick={shareButtonOnClick}
                                        label={(
                                            <SVG
                                                src={files.svg.footerShare}
                                                fill='rgb(227, 45, 44)'
                                                width={20}
                                                height={20}
                                                className='relative'

                                            />
                                        )}
                                        fill={false}
                                        type='bg-white border-2 border-white p-1 border-smcv-primary inline-flex items-center justify-center h-full overflow-none'
                                    />
                                </Flex>
                            </motion.div>
                        </motion.div>
                    </Flex>
                    <Counter passedScreenHeight={isMoreThan100vh} />
                </Flex>
            </motion.div>
        </div>

    );
};

const mapStateToProps = (state: RootState) => ({
    smcvContactInfo: Selectors.uiSMCVContactInfo(state),
    vcardUrl: Selectors.uiGetContactVCard(state),
    userOS: Selectors.authUserPlatform(state),
    isFooterInView: Selectors.uiIsFooterInView(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsOpenContactModal: (data: boolean) => dispatch(Actions.uiSetIsOpenContactModal(data)),
    saveContact: () => dispatch(Actions.contactSaveContactAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNavbar);
