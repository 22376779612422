import React from 'react';

import { motion } from 'framer-motion';

import { ALIGN_ITEMS, COLOR, FONT_SIZE, JUSTIFY_CONTENT } from 'entities/tailwind';

import Motion from 'lib/Motion';

import Flex from './Flex';
import Text from './Text';
import Footer from './Footer';
import Span from './Span';

interface CardComponentProps {
    header?: React.ReactNode;
    desc?: React.ReactNode | string;
    footer?: React.ReactNode;
    background?: string;
    style?:React.CSSProperties;
}

const textStyles = {
    size: FONT_SIZE.smaller,
};

const spanStyles = {
    color: COLOR.tertiary,
};

const Card = ({
    header,
    desc,
    footer,
    background,
    style,
}:CardComponentProps):JSX.Element => {
    const backgroundStyle = background !== undefined ? background : '';
    const cardStyle = `flex w-full bg-white relative min-h-[220px] rounded-t-[20px] overflow-x-hidden text-smcv-primary border-[1px] border-b-0 border-solid border-[rgba(222,222,222)] ${backgroundStyle.trim()}`;

    const renderCardHeader = () => {
        if (header) {
            return header;
        }
        return (<Flex>Card Header</Flex>);
    };

    const renderCardFooter = () => {
        if (footer) {
            return footer;
        }
        return 'Footer Content';
    };

    const renderCardDesc = () => {
        const renderString = (str: string) => {
            return (
                <Flex>
                    <Text {...textStyles}>
                        <Span {...spanStyles}>{str}</Span>
                    </Text>
                </Flex>
            );
        };

        if (desc) {
            if (typeof desc === 'string') {
                return renderString(desc);
            }
            return desc;
        }

        return renderString('Missing Description');
    };

    return (
        <motion.div className='basis-full w-full'>
            <div className={cardStyle.trim()} style={style}>
                <Flex fill align={ALIGN_ITEMS.start} justify={JUSTIFY_CONTENT.between} direction='column' noPadding stagger>
                    {renderCardHeader()}
                    <div className='w-full'>
                        {renderCardDesc()}
                    </div>
                </Flex>
            </div>
            <Footer rounded variant={Motion.textVariant(0.3)}>
                {renderCardFooter()}
            </Footer>
        </motion.div>

    );
};

Card.defaultProps = {
    header: undefined,
    desc: undefined,
    footer: undefined,
    background: undefined,
    style: undefined,
};

export default Card;
