import React from 'react';
import { connect } from 'react-redux';
import QRCodeReact from 'qrcode.react';

import Flex from 'components/Flex';
import Modal from 'components/Modal';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { AppDispatch, RootState } from 'redux/store';

import { ALIGN_ITEMS, JUSTIFY_CONTENT } from 'entities/tailwind';

interface QRModalProps {
    getIsOpenContactModal: boolean;
    getContactVCard: string;
    setIsOpenContactModal(data: boolean): void;
}

const QRModal = ({
    getIsOpenContactModal,
    getContactVCard,
    setIsOpenContactModal,
}:QRModalProps):JSX.Element => {
    return (
        <Modal onClick={() => setIsOpenContactModal(!getIsOpenContactModal)}>
            <Flex direction='column' align={ALIGN_ITEMS.center} justify={JUSTIFY_CONTENT.center} fill>
                <QRCodeReact value={getContactVCard} size={250} />
            </Flex>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    getIsOpenContactModal: Selectors.uiGetIsOpenContactModal(state),
    getContactVCard: Selectors.uiGetContactVCard(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsOpenContactModal: (data: boolean) => dispatch(Actions.uiSetIsOpenContactModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QRModal);
