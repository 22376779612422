import { PlatformEnum } from 'entities/base';

import { AuthState } from '.';

const getLoginAttempting = (state: AuthState): boolean => state.actions.login || false;
const getLoginError = (state: AuthState): string => state.error.login || '';

const getAuthToken = (state: AuthState): string => state.authToken || '';

const userPlatform = (state: AuthState): PlatformEnum => state.platform || 0;
const userId = (state: AuthState): string => state.uuid || '';

export default {
    getLoginAttempting,
    getLoginError,

    getAuthToken,

    userPlatform,
    userId,
};
