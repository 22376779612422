import { EbookLanguageEnum, PlatformEnum } from 'entities/base';

type Status = 'Success' | 'Failed';

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: any | null): Status => {
        if (!item) return 'Failed';
        // eslint-disable-next-line
        const parse = JSON.stringify(item);
        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

const Helper = {
    convertOsStringToEnum: (os: string):PlatformEnum => {
        switch (os) {
            case 'android':
                return PlatformEnum.android;
            case 'ios':
                return PlatformEnum.ios;
            case 'web':
                return PlatformEnum.web;
            default:
                return PlatformEnum.web;
        }
    },
    convertLanguageEnumToString: (value: EbookLanguageEnum):string => {
        switch (value) {
            case EbookLanguageEnum.english: return 'English';
            case EbookLanguageEnum.chinese: return 'Chinese';
            case EbookLanguageEnum.malay: return 'Malay';
            default: return '';
        }
    },
};

export default {
    Auth,
    LocalStorage,
    Helper,
};
