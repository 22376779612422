import React from 'react';
import { ToastContainer } from 'react-toastify';
import AppRoot from 'containers/AppRoot';

import 'react-toastify/dist/ReactToastify.css';

const App = (): JSX.Element => {
    return (
        <div className='App'>
            <AppRoot />
            <ToastContainer />
        </div>
    );
};

export default App;
