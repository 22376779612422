import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EbookLanguageEnum } from 'entities/base';
import { PdfReduxState } from './types';

const initialState: PdfReduxState = {
    actions: {
        downloadEbook: false,
    },
    pdfUrl: '',
    error: {
        downloadEbook: '',
    },
};

const pdfSlice = createSlice({
    name: 'pdf',
    initialState,
    reducers: {
        pdfDownloadEbookAttempt: (state, _action: PayloadAction<EbookLanguageEnum>) => {
            state.actions.downloadEbook = true;
            state.error.downloadEbook = '';
        },
        pdfDownloadEbookFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.downloadEbook = false;
            if (action.payload) {
                state.error.downloadEbook = action.payload;
            }
        },
        pdfDownloadEbookSuccess: (state, action: PayloadAction<string>) => {
            if (action.payload) {
                state.pdfUrl = action.payload;
            }

            state.actions.downloadEbook = false;
            state.error.downloadEbook = '';
        },
    },
});

export type PdfState = typeof initialState;

export default {
    actions: pdfSlice.actions,
    reducers: pdfSlice.reducer,
};
