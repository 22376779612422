import { fork } from 'redux-saga/effects';

import ContactGateway from 'api/Contact';

import { RootSagaReturnType } from 'sagas/types';

import watchGetCounter from './getCounter';
import watchSaveContact from './saveContact';

export default (api: ContactGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetCounter, api);
        yield fork(watchSaveContact, api);
    }
    return {
        rootSaga,
    };
};
