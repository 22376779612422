import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import ContactGateway from 'api/Contact';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchSaveContact(api: ContactGateway): SagaWatcherReturnType {
    yield takeEvery('contact/contactSaveContactAttempt', handleSaveContact, api);
}

function* handleSaveContact(api: ContactGateway) {
    const uuid = yield* select(Selectors.authUserId);
    const platform = yield* select(Selectors.authUserPlatform);

    const res = yield* call([api, api.saveContact], { uuid, platform });

    if (res.status === GatewayResponseStatus.Error) {
        yield put(Actions.contactSaveContactFailure(res.message || ''));
    }

    if (res.status === GatewayResponseStatus.Success) {
        yield put(Actions.contactSaveContactSuccess());
    }
}
