import React from 'react';
import Flex from 'components/Flex';
import { ALIGN_ITEMS, JUSTIFY_CONTENT, SPACING } from 'entities/tailwind';
import { UUID } from 'uuidjs';

interface NumbersContainerProps {
    count: number;
}

const NumberContainer = ({ num, last }:{ num: number | string, last: boolean}):JSX.Element => {
    return (
        <div className='rounded-[5px] text-smcv-primary relative text-[14px] w-5 h-5 flex justify-center items-center font-bold'>
            <div className='bg-[#6B6B6B] min-h-[4px] w-[2px] left-[-1px] absolute z-[1]' />
            {last && (<div className='bg-[#6B6B6B] min-h-[4px] w-[2px] right-[-1px] absolute z-[1]' />)}
            <div className='bg-[#ffffff80] rounded-[5px] h-full top-0 absolute right-0 left-0 z-[0]' />
            <div className=' z-[3]'>{num}</div>
        </div>
    );
};

const Numbers = ({
    count,
}:NumbersContainerProps):JSX.Element => {
    const [total, setTotal] = React.useState<Array<string | number>>([]);

    const formatCount = (num:number):string => {
        const suffixes: {
            // B: number,
            M: number,
            K: number
        } = {
            // B: 1000000000,
            M: 1000000,
            K: 1000,
        };

        let newNum = '';
        Object.entries(suffixes).forEach(([key, value]) => {
            if (num >= value) {
                const formattedNumber = (num / value).toFixed(2);
                newNum = `${formattedNumber.split('.')[0]}${key}`;
            }
        });

        if (newNum.length) {
            return newNum;
        }

        return num.toString();
    };

    React.useEffect(() => {
        if (count > 999999) {
            const shortenedVal = formatCount(count);
            setTotal(shortenedVal.split(''));
        } else {
            setTotal([...`${count}`].map(n => +n));
        }
    }, [count]);

    return (
        <Flex
            xPadding={SPACING.none}
            yPadding={SPACING.none}
            align={ALIGN_ITEMS.stretch}
            justify={JUSTIFY_CONTENT.center}
            gap={SPACING.none}
            salt='gap-[1px] my-[6px] self-end'
            fill={false}
        >
            {total.map((t, index) => {
                const lastElement = index === total.length - 1;
                return (
                    <NumberContainer key={`counter-${UUID.generate()}-${t}`} num={t} last={lastElement} />
                );
            })}
        </Flex>
    );
};

export default Numbers;
