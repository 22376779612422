import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import PdfGateway from 'api/Pdf';
import Utils from 'lib/Utils';

import { IDownloadEbookReduxParam } from 'entities/pdf';

export default function* watchDownloadEbook(api: PdfGateway): SagaWatcherReturnType {
    yield takeEvery('pdf/pdfDownloadEbookAttempt', handleDownloadEbook, api);
}

function* handleDownloadEbook(api: PdfGateway, data: PayloadAction<IDownloadEbookReduxParam>) {
    const language = Number(data.payload);

    const uuid = yield* select(Selectors.authUserId);
    const platform = yield* select(Selectors.authUserPlatform);

    const res = yield* call([api, api.downloadEbook], { uuid, platform, language });

    const lang = Utils.Helper.convertLanguageEnumToString(language);

    if (res.status === GatewayResponseStatus.Error) {
        yield put(Actions.pdfDownloadEbookFailure(res.message || ''));
        toast.error('Download Fail');
    }

    if (res.status === GatewayResponseStatus.Success) {
        yield put(Actions.pdfDownloadEbookSuccess(res.data.pdfUrl));
        yield put(Actions.uiSetEbookModalOpen(false));
        toast.success('E-book downloaded successfully');
        FileSaver.saveAs(res.data.pdfUrl, `E-book (${lang}).pdf`);
    }
}
