import { ISMCVContactInfo } from 'entities/startup';
import { UiState } from '.';

const getIsOpenContactModal = (state: UiState): boolean => state.isOpenContactModal || false;
const getContactVCardUrl = (state: UiState): string => state.contactVCard || '';
const isEbookModalOpen = (state: UiState): boolean => state.isEbookModalOpen || false;
const getSMCVContactInfo = (state: UiState): ISMCVContactInfo => state.contactInformation;
const isFooterInView = (state: UiState): boolean => state.footerIsInView;

export default {
    getIsOpenContactModal,
    getContactVCardUrl,
    isEbookModalOpen,
    getSMCVContactInfo,
    isFooterInView,
};
