export enum EbookLanguageEnum {
    none,
    english,
    chinese,
    malay
}
export enum PlatformEnum {
    none,
    android,
    ios,
    web,
}
