import React, { useEffect } from 'react';
import { useOs } from '@mantine/hooks';
import { connect } from 'react-redux';
import { UUID } from 'uuidjs';

import Actions from 'redux/Actions';
import { AppDispatch } from 'redux/store';

import Routes from 'navigation/Routes';

import Utils from 'lib/Utils';

import { IStartupParams } from 'entities/startup';

import packageJson from '../../package.json';
import config from '../config';

interface AppRootScreenProps {
    startup: (param:IStartupParams) => void;
    getSavedContactCounter(): void;
    generateVcard: () => void;
}

const AppRoot = ({
    startup,
    getSavedContactCounter,
    generateVcard,
}:AppRootScreenProps): JSX.Element => {
    const userOS = useOs().toLowerCase();

    useEffect(() => {
        console.log(`App Version: ${packageJson.version} ${config.env}`);
        generateVcard();
        getSavedContactCounter();
        setInterval(getSavedContactCounter, 10000);
    }, []);

    useEffect(() => {
        const localStorageUuid: string | null = Utils.LocalStorage.getItem('uuid');
        if (localStorageUuid) {
            startup({
                uuid: localStorageUuid.toString(),
                platform: Utils.Helper.convertOsStringToEnum(userOS),
            });
        } else {
            const newUUID: string = UUID.generate();
            Utils.LocalStorage.setItem('uuid', newUUID);
            startup({
                uuid: newUUID,
                platform: Utils.Helper.convertOsStringToEnum(userOS),
            });
        }
    }, [userOS]);

    return (
        <Routes />
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    startup: (param: IStartupParams) => dispatch(Actions.authSetStartup(param)),
    generateVcard: () => dispatch(Actions.uiGenerateContactVCard()),
    getSavedContactCounter: () => dispatch(Actions.contactGetSavedContactCounterAttempt()),
});

export default connect(null, mapDispatchToProps)(AppRoot);
