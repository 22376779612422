import React from 'react';
import { motion } from 'framer-motion';
import SVG from 'react-inlinesvg';

import Motion from 'lib/Motion';

import { ALIGN_ITEMS, BUTTON, COLOR, FONT_SIZE, FONT_WEIGHT, JUSTIFY_CONTENT, SPACING, TEXT_ALIGN } from 'entities/tailwind';

import Span from 'components/Span';
import Text from 'components/Text';
import Flex from 'components/Flex';
import Card from 'components/Card';
import Button from 'components/Button';

import files from 'assets/files';

const PlaygroundScreen = (): JSX.Element => {
    return (
        <motion.section
            variants={Motion.staggerContainer(0.3, 0.5)}
            initial='hidden'
            whileInView='show'
            viewport={{ once: false, amount: 0.1 }}
            className='p-5 relative'
        >

            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe animi, non sequi enim recusandae delectus et commodi quisquam eveniet! Sequi sunt cupiditate nulla error rem officia ab optio esse quis.
            <motion.div
                variants={Motion.slideIn('left', 'spring', 0.5, 1)}

            >

                <Button label='Save Contact' type={BUTTON.clear} />
                <Button isLoading fill label='Save' type={BUTTON.secondary} align={TEXT_ALIGN.end} />
                <Button label='Save' type={BUTTON.primary} align={TEXT_ALIGN.start} />
                <Button label='Save' type={BUTTON.primary} align={TEXT_ALIGN.center} />
                <Button label='Save' type={BUTTON.primary} align={TEXT_ALIGN.left} />
                <Button label='Save' type={BUTTON.primary} align={TEXT_ALIGN.justify} />
                <Button label='Save' type={BUTTON.primary} align={TEXT_ALIGN.right} />
                <Button fill label='Save Contact' type={BUTTON.secondary} align={TEXT_ALIGN.center} />
                {/* Custom Button to overwrite CSS */}
                <Button
                    label={(
                        <SVG
                            src={files.svg.share}
                            fill='rgb(227, 45, 44)'
                            stroke='rgb(227, 45, 44)'
                            width={30}
                            height={30}
                        />
                    )}
                    fill={false}
                    type='bg-smcv-white border-2 border-rose-500 p-5 border-smcv-primary inline-flex items-center justify-center h-15 w-15 overflow-none'
                />
            </motion.div>
            <Text>
                Home&nbsp;
                <Span color={COLOR.primary} weight={FONT_WEIGHT.BOLD}>screen</Span>
            </Text>
            <Text size={FONT_SIZE.smallest}>Playground</Text>
            <Text size={FONT_SIZE.smaller}>Playground</Text>
            <Text size={FONT_SIZE.small}>Playground</Text>
            <Text>Playground</Text>
            <Text size={FONT_SIZE.large}><Span color={COLOR.primary} weight={FONT_WEIGHT.BOLD}>Playground</Span></Text>
            <Text size={FONT_SIZE.larger}>Playground</Text>
            <Text size={FONT_SIZE.largest}>Playground</Text>
            <Text size={FONT_SIZE.largest}><Span>Playground</Span></Text>

            <div>
                <Flex
                    xPadding={SPACING.extraLarge}
                    gap={SPACING.extraLarge}
                    justify={JUSTIFY_CONTENT.between}
                    align={ALIGN_ITEMS.center}
                    direction='column'
                >
                    <Card />
                    <Card />
                    <Card />
                    <Card />
                    <Card />
                </Flex>
            </div>

        </motion.section>
    );
};

export default PlaygroundScreen;
