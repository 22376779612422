import { ContactState } from '.';

const saveContactAttempting = (state: ContactState): boolean => state.actions.saveContact || false;
const saveContactError = (state: ContactState): string => state.error.saveContact || '';

const getSavedContactCounterAttempt = (state: ContactState): boolean => state.actions.savedContactCounter || false;
const getSavedContactCounterError = (state: ContactState): string => state.error.savedContactCounter || '';
const getSavedContactCounter = (state: ContactState): number => state.savedCount || 0;

export default {
    saveContactAttempting,
    saveContactError,
    getSavedContactCounterAttempt,
    getSavedContactCounterError,
    getSavedContactCounter,
};
